export const isServer = 'undefined' === typeof window
export const isBrowser = !isServer
export const isTest = process.env.NEXT_PUBLIC_ENV === 'test'
// export const baseURL = isTest ? 'https://tests.yome.finance/api' : 'https://yome.finance/api'
export const baseURL = isTest ? 'https://tests.yome.finance/api' : 'https://yome.finance/api'
// export const baseURL = isTest ? 'https://api.tproxy.cc/api' : '/api'

export const vBaseURL = 'http://yome-api/api'

export const GaId = ''

// export const ADDRESS_PLEDGE = isTest
//   ? 'CvUuSr8MNYAnCtAH36DMFGqeD346YDb1c5HXrPUdwVRc'
//   : '99qmAabxrVHNtnK7fkt5Yj2QEUDpKnnE7T39JxFYRsW6'
export const ADDRESS_SOL_PLEDGE = isTest
  ? 'CvUuSr8MNYAnCtAH36DMFGqeD346YDb1c5HXrPUdwVRc'
  : 'CvUuSr8MNYAnCtAH36DMFGqeD346YDb1c5HXrPUdwVRc'

export const ADDRESS_TON_PLEDGE = isTest
  ? 'EQAAqm18BzicztVivKQW_KdBLL0f7VqLngywF6kDn7oXP0QA'
  : 'EQCzNhFgak2GAwpxCpulqs1hFV7hhnhmr7FykuZJ9OcZvBJv'

export const isIphone = () => {
  if (isServer) return false
  return !!window.navigator.userAgent.match(/iPhone/i)
}
