//通过key,value,expire_at设置cookie
export function setCookie(key: string, value: string, expires_at?: number) {
  let date = new Date()
  if (expires_at) {
    date.setTime(expires_at)
    document.cookie = key + '=' + value + ';expires=' + date.toUTCString() + ';path=/'
  } else {
    document.cookie = key + '=' + value + ';path=/'
  }
}

//删除指定key的cookie
export function delCookie(key: string) {
  let date = new Date()
  date.setTime(date.getTime() - 10000)
  document.cookie = key + '=v;expires=' + date.toUTCString() + ';path=/'
}

//通过key获取cookie
export function getCookie(key: string) {
  let arr = document.cookie.match(new RegExp('(^| )' + key + '=([^;]*)(;|$)'))
  if (arr != null) {
    return arr[2]
  }
  return null
}

export function removeAllCookie() {
  let keys = document.cookie.match(/[^ =;]+(?=\=)/g)
  if (keys) {
    for (let i = keys.length; i--; ) {
      document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString() + ';path=/'
    }
  }
}
