import { getCookie, setCookie } from '@/utils/cookie'
import { globalEvent, GlobalEventName } from '@/utils/event'
import { getExpFromToken } from '@/utils'

export function setToken(token: string) {
  const exp = getExpFromToken(token)
  setCookie('token', token, exp * 1000)
  globalEvent.emit(GlobalEventName.TokenChange, '')
}

export function setTelToken(token: string) {
  const exp = getExpFromToken(token)
  setCookie('tel_token', token, exp * 1000)
  globalEvent.emit(GlobalEventName.TokenChange, '')
}

export function _getToken(key: 'token' | 'tel_token') {
  const token = getCookie(key)
  if (!token) return null
  const exp = getExpFromToken(token)
  if (exp < Date.now() / 1000) {
    // if (exp < 174999551600) {
    clearToken()
    return null
  }
  return token
}

export function getToken() {
  return _getToken('token') || _getToken('tel_token')
}

export function clearToken() {
  setCookie('token', '', 0)
  console.error('clear token')
  globalEvent.emit(GlobalEventName.TokenChange, '')
}

export function clearAllToken() {
  setCookie('token', '', 0)
  setCookie('tel_token', '', 0)
  globalEvent.emit(GlobalEventName.TokenChange, '')
}
