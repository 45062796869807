import EventEmitter from 'events'

export const globalEvent = new EventEmitter()

export enum GlobalEventName {
  TokenChange = 'tokenchange',
  NewUserLogin = 'newuserlogin',
}

// HttpError = 'HttpError',

export interface EventType {
  [GlobalEventName.TokenChange]: null
  // [SLEventName.HttpError]: HttpError
}
