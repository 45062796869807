const AIRDROP = {
  NEWBIEPACKAGEBG_PNG: '/images/airdrop/NewbiePackageBg.png',
  ARROW_SVG: '/images/airdrop/arrow.svg',
  LUCKYICON_PNG: '/images/airdrop/luckyicon.png',
  NEWBIE_PNG: '/images/airdrop/newbie.png',
  POINTSICON_PNG: '/images/airdrop/pointsicon.png',
  SOLANA_SVG: '/images/airdrop/solana.svg',
  TEXTICON_PNG: '/images/airdrop/texticon.png',
  TEXTICON2_PNG: '/images/airdrop/texticon2.png',
  WEN_PNG: '/images/airdrop/wen.png',
}
const ASIDER = {
  ACTIVITIES_SVG: '/images/asider/activities.svg',
  AIRDROP_SVG: '/images/asider/airdrop.svg',
  AIRDROP_ACTIVE_SVG: '/images/asider/airdrop_active.svg',
  AIRDROP_YELLOW_SVG: '/images/asider/airdrop_yellow.svg',
  EVENT_SVG: '/images/asider/event.svg',
  EVENT_YELLOW_SVG: '/images/asider/event_yellow.svg',
  GAME_SVG: '/images/asider/game.svg',
  GAME1_S_SVG: '/images/asider/game1-s.svg',
  GAME1_SVG: '/images/asider/game1.svg',
  GAME2_S_SVG: '/images/asider/game2-s.svg',
  GAME2_SVG: '/images/asider/game2.svg',
  GAME3_S_SVG: '/images/asider/game3-s.svg',
  GAME3_SVG: '/images/asider/game3.svg',
  GAME4_S_SVG: '/images/asider/game4-s.svg',
  GAME4_SVG: '/images/asider/game4.svg',
  GAMEBLACK_SVG: '/images/asider/gameblack.svg',
  HOME_SVG: '/images/asider/home.svg',
  HOME_ACTIVE_SVG: '/images/asider/home_active.svg',
  HOME_YELLOW_SVG: '/images/asider/home_yellow.svg',
  INTEGRAL_SVG: '/images/asider/integral.svg',
  INTEGRAL_ACTIVE_SVG: '/images/asider/integral_active.svg',
  INTEGRAL_YELLOW_SVG: '/images/asider/integral_yellow.svg',
  INVITE_SVG: '/images/asider/invite.svg',
  INVITE_ACTIVE_SVG: '/images/asider/invite_active.svg',
  INVITE_YELLOW_SVG: '/images/asider/invite_yellow.svg',
  LANGUAGE_SVG: '/images/asider/language.svg',
  LEI_SVG: '/images/asider/lei.svg',
  PERSONALCENTER_SVG: '/images/asider/personalcenter.svg',
  PERSONALCENTER_ACTIVE_SVG: '/images/asider/personalcenter_active.svg',
  PERSONALCENTER_YELLOW_SVG: '/images/asider/personalcenter_yellow.svg',
}
const COMMON = {
  ARROW_PNG: '/images/common/arrow.png',
  ARROW2_PNG: '/images/common/arrow2.png',
  LEFT_PNG: '/images/common/left.png',
  LOADING_GIF: '/images/common/loading.gif',
  RIGHT_PNG: '/images/common/right.png',
  SITE_S_SVG: '/images/common/site-s.svg',
  SITE_SVG: '/images/common/site.svg',
}
const GAME = {
  ADD_SVG: '/images/game/add.svg',
  BUY_PNG: '/images/game/buy.png',
  FINGER_CLICK_PNG: '/images/game/finger_click.png',
  FINGER_DOWN_PNG: '/images/game/finger_down.png',
  FINGER_LEFT_PNG: '/images/game/finger_left.png',
  FINGER_PRESS_PNG: '/images/game/finger_press.png',
  FINGER_RIGHT_PNG: '/images/game/finger_right.png',
  FINGER_SWAPE_PNG: '/images/game/finger_swape.png',
  FINGER_UP_PNG: '/images/game/finger_up.png',
  FLAPPYBIRD_PNG: '/images/game/flappybird.png',
  ICON_2048_PNG: '/images/game/icon_2048.png',
  ICON_DOG_PNG: '/images/game/icon_dog.png',
  ICON_FLAPPY_SVG: '/images/game/icon_flappy.svg',
  ICON_MINE_PNG: '/images/game/icon_mine.png',
  LEADER_LEFT_SVG: '/images/game/leader_left.svg',
  LEADER_MEDAL_PNG: '/images/game/leader_medal.png',
  LEADER_RIGHT_SVG: '/images/game/leader_right.svg',
  LEFTCIR_SVG: '/images/game/leftcir.svg',
  MEME_COVER_PNG: '/images/game/meme_cover.png',
  MINUS_SVG: '/images/game/minus.svg',
  MOUSELEFT_SVG: '/images/game/mouseLeft.svg',
  MOUSE_LEFT_RIGHT_SVG: '/images/game/mouse_left_right.svg',
  NO_REWARD_SVG: '/images/game/no_reward.svg',
  PLAY_SVG: '/images/game/play.svg',
  RANKING_CLOSE_SVG: '/images/game/ranking_close.svg',
  RANKING_DEFAULT_AVATAR_PNG: '/images/game/ranking_default_avatar.png',
  RANKING_MEDAL_PNG: '/images/game/ranking_medal.png',
  RESET_SVG: '/images/game/reset.svg',
  SHAREICON_PNG: '/images/game/shareicon.png',
  STAKE_PNG: '/images/game/stake.png',
}
const GUIDE = {
  GUIDE1_PNG: '/images/guide/guide1.png',
  GUIDE2_PNG: '/images/guide/guide2.png',
  GUIDE3_PNG: '/images/guide/guide3.png',
}
const HEADER = {
  BORDER_SVG: '/images/header/border.svg',
  LANGUAGE_SVG: '/images/header/language.svg',
  PATHICON_PNG: '/images/header/pathicon.png',
  PATHSTAR_PNG: '/images/header/pathstar.png',
  POINTS_SVG: '/images/header/points.svg',
  SOLANA_SVG: '/images/header/solana.svg',
  TON_PNG: '/images/header/ton.png',
  TONICON_PNG: '/images/header/tonicon.png',
}
const HOME = {
  BANNER_2048_PNG: '/images/home/banner/2048.png',
  BANNER_DOGEJUMPCHALLENGE_PNG: '/images/home/banner/DogeJumpChallenge.png',
  BANNER_FLAPPYCRYPTO_PNG: '/images/home/banner/FlappyCrypto.png',
  BANNER_MEME_MERGE2048_PNG: '/images/home/banner/MEME-Merge2048.png',
  BANNER_MEME_MINESWEEPER_PNG: '/images/home/banner/MEME-MineSweeper.png',
  BANNER_SWEE_PNG: '/images/home/banner/SWEE.png',
  BANNER_DOGE_JUMP_PNG: '/images/home/banner/doge-jump.png',
  BANNER_FLA_PNG: '/images/home/banner/fla.png',
  BINANCE_SVG: '/images/home/binance.svg',
  BIRD2_SVG: '/images/home/bird2.svg',
  CLOUD_SVG: '/images/home/cloud.svg',
  CLOUD2_SVG: '/images/home/cloud2.svg',
  COMBO_SVG: '/images/home/combo.svg',
  FBG_SVG: '/images/home/fbg.svg',
  FLY_BIRD_SVG: '/images/home/fly_bird.svg',
  GAMEFIICON_PNG: '/images/home/gamefiicon.png',
  GAMEFIICON_SVG: '/images/home/gamefiicon.svg',
  GATEIO_SVG: '/images/home/gateio.svg',
  HOMETITLEICON_SVG: '/images/home/hometitleicon.svg',
  LEM_SVG: '/images/home/lem.svg',
  MOBILE_BANNER: '/images/home/mobile/banner',
  MOBILE_GAME: '/images/home/mobile/game',
  OKX_SVG: '/images/home/okx.svg',
  PANDA_PNG: '/images/home/panda.png',
  PIC1_SVG: '/images/home/pic1.svg',
  PIC2_SVG: '/images/home/pic2.svg',
  PIC3_SVG: '/images/home/pic3.svg',
  PIC4_SVG: '/images/home/pic4.svg',
  SOLOLA_SVG: '/images/home/solola.svg',
  TONP_PNG: '/images/home/tonp.png',
}
const ICON = {
  CLOSE_SVG: '/images/icon/close.svg',
  CLOSE_SM_SVG: '/images/icon/close_sm.svg',
  CLOSE_SM2_SVG: '/images/icon/close_sm2.svg',
  CLOSE_SM3_SVG: '/images/icon/close_sm3.svg',
  ERROR_SVG: '/images/icon/error.svg',
  ERROR_LIGHT_SVG: '/images/icon/error_light.svg',
  LOADING_SVG: '/images/icon/loading.svg',
  LOADING_GRAY_SVG: '/images/icon/loading_gray.svg',
  LOGOUT_SVG: '/images/icon/logout.svg',
  RETRY_SVG: '/images/icon/retry.svg',
  SUCCESS_SVG: '/images/icon/success.svg',
}
const INTEGRAL = {
  DEPOSITBG_PNG: '/images/integral/depositbg.png',
  DOG_SVG: '/images/integral/dog.svg',
  FPEPE_SVG: '/images/integral/fpepe.svg',
  PEPE_SVG: '/images/integral/pepe.svg',
  RIMG_SVG: '/images/integral/rimg.svg',
  SHIBA_SVG: '/images/integral/shiba.svg',
  WIF_SVG: '/images/integral/wif.svg',
}
const INVITE = {
  BG_PNG: '/images/invite/bg.png',
  MODALBOTTOM_PNG: '/images/invite/modalbottom.png',
  MODALBOTTOM_SVG: '/images/invite/modalbottom.svg',
  MODALBOTTOMBG2_SVG: '/images/invite/modalbottombg2.svg',
  REFERRALS_PNG: '/images/invite/referrals.png',
  STAR_SVG: '/images/invite/star.svg',
  YOME_PNG: '/images/invite/yome.png',
}
const MOBILEMENU = {
  ICON_AIRDROP_PNG: '/images/mobilemenu/icon_airdrop.png',
  ICON_AIRDROP_CLICK_PNG: '/images/mobilemenu/icon_airdrop_click.png',
  ICON_GAME_PNG: '/images/mobilemenu/icon_game.png',
  ICON_GAME_CLICK_PNG: '/images/mobilemenu/icon_game_click.png',
  ICON_INVITE_PNG: '/images/mobilemenu/icon_invite.png',
  ICON_INVITE_CLICK_PNG: '/images/mobilemenu/icon_invite_click.png',
  ICON_MY_PNG: '/images/mobilemenu/icon_my.png',
  ICON_MY_CLICK_PNG: '/images/mobilemenu/icon_my_click.png',
  ICON_POINTS_PNG: '/images/mobilemenu/icon_points.png',
  ICON_POINTS_CLICK_PNG: '/images/mobilemenu/icon_points_click.png',
}
const MY = {
  ADD_LUCK_CHANCE_PNG: '/images/my/add_luck_chance.png',
  BOT_USER_PNG: '/images/my/bot_user.png',
  BUFFCARD_1S_GRAY_PNG: '/images/my/buffcard/1s-gray.png',
  BUFFCARD_1S_PNG: '/images/my/buffcard/1s.png',
  BUFFCARD_2S_GRAY_PNG: '/images/my/buffcard/2s-gray.png',
  BUFFCARD_2S_PNG: '/images/my/buffcard/2s.png',
  BUFFCARD_3S_GRAY_PNG: '/images/my/buffcard/3s-gray.png',
  BUFFCARD_3S_PNG: '/images/my/buffcard/3s.png',
  BUFFCARD_4S_GRAY_PNG: '/images/my/buffcard/4s-gray.png',
  BUFFCARD_4S_PNG: '/images/my/buffcard/4s.png',
  BUFFCARD_5S_GRAY_PNG: '/images/my/buffcard/5s-gray.png',
  BUFFCARD_5S_PNG: '/images/my/buffcard/5s.png',
  BUFFCARD_BOX_PNG: '/images/my/buffcard/box.png',
  BUFFCARD_未命名文件夹: '/images/my/buffcard/未命名文件夹',
  CHANNELICON_PNG: '/images/my/channelicon.png',
  CHATGROUP_PNG: '/images/my/chatgroup.png',
  CONGRAUTIONRESULT_PNG: '/images/my/congrautionresult.png',
  DEFAULT_AVATAR_PNG: '/images/my/default_avatar.png',
  GETNORMALNFTICON_PNG: '/images/my/getNormalNftIcon.png',
  GETSNFT_PNG: '/images/my/getsnft.png',
  ICON_GO_PNG: '/images/my/icon_go.png',
  ICON_YES_PNG: '/images/my/icon_yes.png',
  INVITEBGICON_PNG: '/images/my/invitebgicon.png',
  LIKETWITTERTASK_PNG: '/images/my/likeTwitterTask.png',
  PLAYONCEDAILY_PNG: '/images/my/playoncedaily.png',
  POINT_PNG: '/images/my/point.png',
  RECORDING_PNG: '/images/my/recording.png',
  RETWEETICON_PNG: '/images/my/retweeticon.png',
  SNFTICON_PNG: '/images/my/snfticon.png',
  TABS_BUFFCARD_PNG: '/images/my/tabs/buffcard.png',
  TABS_MYNFT_PNG: '/images/my/tabs/mynft.png',
  TABS_MYTASK_PNG: '/images/my/tabs/mytask.png',
  TELEGRAM_PNG: '/images/my/telegram.png',
  TWEETICON_PNG: '/images/my/tweeticon.png',
  TWITTER_PNG: '/images/my/twitter.png',
}
const NFT = {
  PEPE_MEMENFT: '/images/nft/pepe/memenft',
  PEPE_PEPE1_SVG: '/images/nft/pepe/pepe1.svg',
  PEPE_PEPE2_SVG: '/images/nft/pepe/pepe2.svg',
  PEPE_PEPE3_SVG: '/images/nft/pepe/pepe3.svg',
  PEPE_PEPE4_SVG: '/images/nft/pepe/pepe4.svg',
  PEPE_PEPEICON_SVG: '/images/nft/pepe/pepeicon.svg',
  PEPE_SMEMENFT: '/images/nft/pepe/smemenft',
}
const POINTS = {
  D_50PER_PNG: '/images/points/50per.png',
  BORDER_SVG: '/images/points/border.svg',
  CHECKED_PNG: '/images/points/checked.png',
  DOGE_PNG: '/images/points/doge.png',
  MINARROWDOWN_PNG: '/images/points/minarrowdown.png',
  NFTBORDER_SVG: '/images/points/nftborder.svg',
  SANDIAN_PNG: '/images/points/sandian.png',
}
const REDPKG = {
  ARROW_PNG: '/images/redPkg/arrow.png',
  CLOCK_PNG: '/images/redPkg/clock.png',
  CONGRADUTIONIMG_PNG: '/images/redPkg/congradutionimg.png',
  CONGRADUTIONIMG1_PNG: '/images/redPkg/congradutionimg1.png',
  CONGRADUTIONIMG2_PNG: '/images/redPkg/congradutionimg2.png',
  DEGO_PNG: '/images/redPkg/dego.png',
  JIAO_PNG: '/images/redPkg/jiao.png',
  NOPOINTSRESULT_PNG: '/images/redPkg/noPointsResult.png',
  PKG_PNG: '/images/redPkg/pkg.png',
  YOME_PNG: '/images/redPkg/yome.png',
}
const SOCIETY = {
  S_SVG: '/images/society/S.svg',
  DISCARD_SVG: '/images/society/discard.svg',
  DOC_SVG: '/images/society/doc.svg',
  GAME_SVG: '/images/society/game.svg',
  GITHUB_SVG: '/images/society/github.svg',
  TEL_SVG: '/images/society/tel.svg',
  TWITTER_SVG: '/images/society/twitter.svg',
}
const TOKEN = {
  DOG_SVG: '/images/token/dog.svg',
  DOG2_SVG: '/images/token/dog2.svg',
  PEPE_SVG: '/images/token/pepe.svg',
  TOKEN4_SVG: '/images/token/token4.svg',
}
export const Images = {
  AIRDROP,
  ASIDER,
  COMMON,
  GAME,
  GUIDE,
  HEADER,
  HOME,
  ICON,
  INTEGRAL,
  INVITE,
  MOBILEMENU,
  MY,
  NFT,
  POINTS,
  REDPKG,
  SOCIETY,
  TOKEN,
}
