import { getHttpService, postHttpService } from '@/utils/http/service'

export const userService = {
  getWalletNonce: getHttpService<string, {}>('/v1/user/wallet/nonce'),
  getWalletPayload: getHttpService<string, {}>('/v1/user/wallet/payload'),
  login: postHttpService<
    { token: string; expires_in: number },
    | {
        chain_id: number
        address: string
        public_key: string
        signature: string
        nonce: string
        invite_code?: string
        invite_root_code?: string
      }
    | {
        chain_id: number
        invite_code?: string
        proof?: any
        invite_root_code?: string
      }
  >('/v1/user/wallet/login'),
  getUserInfo: getHttpService<UserInfo, {}>('/v1/user/info'),
  loginWithTelegram: postHttpService<
    {
      token: string
      expires_in: number
      telegram_new_user: boolean
    },
    {
      invite_code?: string
      telegram_data: string
      invite_root_code?: string
    }
  >('/v1/user/telegram/login'),
  bindWallet: postHttpService<
    null,
    { chain_id: number; address?: string; public_key?: string; signature?: string; nonce?: string; proof: string }
  >('/v1/user/telegram/binding-wallet'),
  checkWallet: getHttpService<
    { registered: boolean; bind_current_telegram: boolean },
    {
      address: string
      telegram_id: number
    }
  >('/v1/user/wallet/check'),
  updateUserTelegram: postHttpService<null, { telegram_data: string }>('/v1/user/telegram/update'),
}

export interface UserInfo {
  user_id: number
  user_address: string
  user_avatar: string
  user_nick_name: string
  twitter_name: string
  twitter_user_name: string
  twitter_avatar: string
  twitter_is_valid: boolean
  discord_name: string
  discord_user_name: string
  discord_avatar: string
  invited_user_count: number
  points: number
  token_staking_points: number
  nft_staking_points: number
  referral_points: number
  task_points: number
  additional_times: number
  purchased_newbie_gift: boolean
  mystery_box_count: number
  telegram_id: number
  telegram_user_name: string
  token_staking_dego: number
  dego_balance: number
  nft_staking_dego: number
  code: string
  root_code: string
}
