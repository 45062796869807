import { userService } from '@/utils/http/services/user'
import TonWeb from 'tonweb'

export function sleep(time: number) {
  return new Promise((resolve, reject) => setTimeout(resolve, time))
}

export function getErrorMsg(e: any) {
  return e.reason || e.data?.message || e.message
}

export function isSameHash(hash1?: string, hash2?: string) {
  if (!hash1 || !hash2) return false
  if (hash1 === hash2) return true
  return hash1.toLowerCase() === hash2.toLowerCase()
}

export function NoOperation() {}
export async function NoOperationAsync() {}

export function getExpFromToken(token: string) {
  const tokenArr = token.split('.')
  if (tokenArr.length !== 3) {
    return 0
  }
  const payload = JSON.parse(atob(tokenArr[1]))
  return payload.exp
}

export const getSignText = async () => {
  const nonce = await userService.getWalletNonce()
  return {
    // msg: `Welcome to MemeGame! Authentication status refreshes in 24 hours.Nonce:${nonce}`,
    msg: `Welcome to YOME!
YOME is a dynamic platform that blends multiple meme-based games.
Web3 MEME Gaming Platform
Nonce:${nonce}`,
    nonce,
  }
}

export function isValidUrl(url: string) {
  try {
    new URL(url)
    return true
  } catch (err) {
    return false
  }
}

export const cutOffStr = (str: string, length: number) => {
  const arr = str.split('.')
  if (arr[1]) {
    return `${arr[0]}.${arr[1].slice(0, length)}`
  } else {
    return str
  }
}

export function isSameTonAddress(address: string, address2: string) {
  const addr1Hex = new TonWeb.Address(address).toString(false)
  const addr2Hex = new TonWeb.Address(address2).toString(false)
  return addr1Hex === addr2Hex
}
export const judgeIsInRedPkgRainTime = () => {
  const hour = new Date().getHours()
  const minutes = new Date().getMinutes()
  // return hour === 12 && minutes >= 0 && minutes <= 30
  return true
}
