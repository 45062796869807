import { baseURL, isBrowser, vBaseURL } from '@/utils/env'
import { getToken } from '@/utils/http/token'

const BASE_URL = baseURL

export interface Params {}

let cache: Promise<boolean> | null = null
export async function getBaseURL() {
  if (process.env.NEXT_PUBLIC_VBACKEND === 'false' || isBrowser) {
    return BASE_URL
  }
  if (!cache) {
    cache = fetch(vBaseURL + '/api/ping')
      .then((res) => {
        return true
      })
      .catch(() => {
        return false
      })
  }
  if (await cache) {
    return vBaseURL
  } else {
    return BASE_URL
  }
}
async function request<T>(_url: string, _config: RequestInit & { query?: Params; data?: Params; token?: string }) {
  try {
    const url = await formatURL(_url, _config.query)
    const config = formatConfig(_config)
    const response = await fetch(url, config)
    const result = await response.json()
    return result as T
  } catch (e) {
    console.log('e', e)
    // handle 401 redirect to login page
    throw e
  }
}

async function formatURL(_url: string, query?: Params) {
  let url = _url
  if (query) {
    url = formatQueryURL(_url, query)
  }
  if (url.startsWith('http')) {
    return url
  } else {
    const baseURL = await getBaseURL()
    return baseURL + url
  }
}
function formatQueryURL(_url: string, _query: Params) {
  const [url, queryStr] = _url.split('?')
  const query = Object.assign({}, _query)
  if (queryStr) {
    const baseQuery = Object.fromEntries(queryStr.split('&').map((x) => x.split('=')))
    Object.assign(query, baseQuery)
  }
  const targetQueryStr = Object.entries(query)
    .filter((x) => x[1] !== undefined)
    .map((x) => `${x[0]}=${x[1]}`)
    .join('&')
  return url + '?' + targetQueryStr
}

function formatConfig(
  _config: RequestInit & { query?: Params; data?: Params; lang?: string; token?: string },
): RequestInit {
  const config = Object.assign({}, _config, { query: undefined, data: undefined })
  const headers = Object.assign({}, config.headers)
  if (_config.data) {
    Object.assign(headers, { 'Content-Type': 'application/json' })
    config.body = JSON.stringify(_config.data)
  }
  let token = ''
  if (isBrowser) {
    token = getToken() || ''
    //read token from storage
  }
  if (config.token) {
    token = config.token
  }
  Object.assign(headers, { Authorization: token ? `Bearer ${token}` : undefined })
  config.headers = headers
  return config
}

export const http = {
  request,
}
